import * as React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import PopoverArow from '@components/common/popover-arow';
export interface ISiderComponentProps {}

export default function SiderMobileComponent(props: ISiderComponentProps) {
  const transaction = () => (
    <div className="transaction-list">
      <Link className="menuSelect" to="/thanh-vien/nap-tien">
        <span className="">Nạp tiền</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/chuyen-diem">
        <span className="chuyen-diem">Chuyển điểm</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/rut-tien">
        <span className="">Rút tiền</span>
      </Link>
    </div>
  );
  const history = () => (
    <div className="transaction-list">
      <Link className="menuSelect" to="/thanh-vien/lich-su-nap-tien">
        <span className="">Lịch sử nạp tiền</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/lich-su-rut-tien">
        <span className="">Lịch sử rút tiền</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/lich-su-gioi-thieu">
        <span className="icon  ">Lịch sử giới thiệu</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/chuyen-diem">
        <span className="">Lịch sử chuyển điểm</span>
      </Link>
    </div>
  );

  const infoUser = () => (
    <div className="transaction-list">
      <Link className="menuSelect" to="/thanh-vien/tro-choi">
        <span className="">Tài khoản cá cược</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/doi-mat-khau">
        <span className="">Đổi Mật Khẩu</span>
      </Link>
      <Link aria-current="page" to="/thanh-vien/ho-so" className="menuSelect active">
        <span className="">Hồ sơ</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/thong-tin-ngan-hang">
        <span className="">Thông tin ngân hàng</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/gioi-thieu">
        <span className="icon  ">Giới thiệu bạn bè</span>
      </Link>
    </div>
  );
  return (
    <div id="leftMenuMobile">
      <PopoverArow content={transaction} allowArow={true} classCustom="dark-style menu-member-mobile">
        <div className="wallet-content menu-item">Giao dịch</div>
      </PopoverArow>
      <PopoverArow content={history} allowArow={true} classCustom="dark-style menu-member-mobile">
        <div className="wallet-content menu-item">Lịch sử</div>
      </PopoverArow>
      <PopoverArow content={infoUser} allowArow={true} classCustom="dark-style menu-member-mobile">
        <div className="wallet-content menu-item">Tài khoản của tôi</div>
      </PopoverArow>
    </div>
  );
}
