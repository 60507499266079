import * as React from 'react';
import './style.scss';
import TabEventMobile from './tab-event';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getBody } from '@store/homeSlice';
import { getAllEvent, getCategory } from '@store/eventSlice';
export interface IEventPageProps {}

export default function EventPage(props: IEventPageProps) {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getAllEvent({ type: 1, page: 1 }));
    dispatch(getCategory());
  }, []);
  return (
    <div className="layout-full">
      <div className="layout-max background-event">
        <div className="title">
          <i className="fa-solid fa-gift"></i>
          <span>Khuyến mãi</span>
        </div>
        <TabEventMobile />
      </div>
    </div>
  );
}
