import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { IMenu } from '@interfaces/Home';
import { Link } from 'react-router-dom';
import { getFooter } from '@store/homeSlice';
import { getSetting } from '@store/settingSlice';
type MenuItem = Required<MenuProps>['items'][number];
function getItem(label: React.ReactNode, key: React.Key, children?: MenuItem[], type?: 'group'): MenuItem {
  return {
    key,
    children,
    label,
    type,
  } as MenuItem;
}

// submenu keys of first level
const rootSubmenuKeys = [''];
type IMenuComponent = {
  menu: IMenu[] | null;
  onClose: () => void;
};

const MenuComponent = ({ menu, onClose }: IMenuComponent) => {
  const { data: dataHome } = useAppSelector((state) => state.homeSlice);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getFooter());
    dispatch(getSetting());
  }, []);
  const items: MenuItem[] = [
    getItem(
      <div className="menu-main-title title-single">
        <Link onClick={onClose} to={`/`}>
          <i className="fa-solid fa-house"></i> Trang chủ
        </Link>
      </div>,
      'sub1',
    ),
    getItem(
      <div className="menu-main-title">
        {/* <i className="fa-solid fa-info"></i> */}
        <i className="fa-solid fa-circle-info"></i>
        Đại lí
      </div>,
      'sub2',
      dataHome.footer?.agent.map((itemSub) =>
        getItem(
          <Link onClick={onClose} to={`/${itemSub.slug}`}>
            {itemSub.title}
          </Link>,
          itemSub.title,
        ),
      ),
    ),
    getItem(
      <div className="menu-main-title">
        <i className="fa-regular fa-circle-question"></i>
        Hỗ trợ
      </div>,
      'sub3',
      dataHome.footer?.suport.map((itemSub) =>
        getItem(
          <Link onClick={onClose} to={`/${itemSub.slug}`}>
            {itemSub.title}
          </Link>,
          itemSub.title,
        ),
      ),
    ),
    getItem(
      <div className="menu-main-title">
        <i className="fa-solid fa-shield-halved"></i>
        Chính sách chúng tôi
      </div>,
      'sub4',
      dataHome.footer?.policy.map((itemSub) =>
        getItem(
          <Link onClick={onClose} to={`/${itemSub.slug}`}>
            {itemSub.title}
          </Link>,
          itemSub.title,
        ),
      ),
    ),
  ];
  const menuItem = menu?.map((item) => {
    if (item.child.length > 0) {
      return getItem(
        <div className="menu-main-title">
          {/* {item.icon} */}
          {item.title}
        </div>,
        String(item.id),
        item.child.map((subItem) => getItem(subItem.title, item.id + '-' + subItem.id)),
      );
    }
    return getItem(
      <div className="menu-main-title title-single">
        {/* {item.icon} */}
        {item.title}
      </div>,
      String(item.id),
    );
  });
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      {menuItem && (
        <Menu
          className="menu-help"
          mode="inline"
          onOpenChange={onOpenChange}
          style={{ width: '100%' }}
          items={[...items]}
          selectable={false}
        />
      )}
    </>
  );
};

export default MenuComponent;
