import * as React from 'react';
import './style.scss';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import { ICreateUser } from '@interfaces/User';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { creatUser } from '@store/authSlice';
import showMessage from '@utils/helper';
import { getPointPrice } from '@store/pointPriceSlice';

export interface IRegisterComponentProps {}

export default function RegisterComponent(props: IRegisterComponentProps) {
  const dispatch = useAppDispatch();
  const { data: DataPointPrice } = useAppSelector((state) => state.pointPriceSlice);
  const { data } = useAppSelector((state) => state.homeSlice);
  const [form] = Form.useForm();
  const handleChangeImg = (imgList: Object[]) => {};
  const [param, setParam] = React.useState<ICreateUser>();
  const handleResetFile = () => {
    form.resetFields();
  };
  React.useEffect(() => {
    dispatch(getPointPrice());
  }, []);
  const handleSubmit = (event) => {
    setParam({ ...param, ...event, birthday: event?.birthday?.format('YYYY-MM-DD') });
    dispatch(
      creatUser({
        param: { ...param, ...event, birthday: event?.birthday?.format('YYYY-MM-DD') },
        callback: (message) => {
          showMessage(message);
          handleResetFile();
        },
      }),
    );
  };
  const { Option } = Select;
  return (
    <div className="layout-full">
      <div className="layout-max register-container">
        <Form
          name="basic"
          className="form-register"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleSubmit}
          layout={'vertical'}
          autoComplete="off"
          form={form}
        >
          <Row gutter={10} className="row-form" justify="center">
            <Col span={24}>
              <Form.Item className="avatar" label="Ảnh đại diện" name="avatar">
                <UploadMutipleImage limit={1} handleChangeImg={handleChangeImg} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Tên đăng nhập"
                name="username"
                rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tên thật"
                name="real_name"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Xác nhận mật khẩu"
                name="password_confirm"
                rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Tỷ giá điểm"
                name="point_price"
                rules={[{ required: true, message: 'Vui lòng chọn thông tin ở đây' }]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={DataPointPrice?.listPointPrice?.map((item) => ({
                    value: Number(item.price),
                    label: item.title,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Giới tính"
                name="gender"
                rules={[{ required: true, message: 'Vui lòng chọn thông tin ở đây' }]}
              >
                <Select allowClear>
                  <Option value="0">Nam</Option>
                  <Option value="1">Nữ</Option>
                  <Option value="2">Khác</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Ngày sinh"
                name="birthday"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <DatePicker placeholder="" format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Số điện thoại"
                name="mobile"
                rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Zalo"
                name="zalo"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Telegram"
                name="telegram"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Viber"
                name="viber"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Form.Item
                label="Biệt danh"
                name="nick_name"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mã giới thiệu"
                name="invite_code"
                rules={[{ required: false, message: 'Vui lòng điền thông tin vào đây' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className="btnGroup">
            <Row gutter={10} className="row-form" justify="center">
              <Col span={12}>
                <Button htmlType="submit" className="btn-check">
                  Đăng ký
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
}
