import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataBankAdmin, StateBankAdmin, IParamListBankAdmin } from '@interfaces/BankAdmin';
import BankAdmin from '@services/BankAdmin';

const initialState: StateBankAdmin<IDataBankAdmin> = {
  data: {
    method: null,
    listBank: [],
  },
  loading: false,
  error: '',
};

export const getAllBankAdmin = createAsyncThunk(
  'BankAdmin/getAllBankAdmin',
  async (payload: Partial<IParamListBankAdmin>, { getState }) => {
    try {
      const { data: dataRes } = await BankAdmin.getAllBankAdmin({ ...payload });
      return {
        listBank: [...dataRes.data],
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getBankAdminCard = createAsyncThunk('BankAdmin/getBankAdminCard', async (_, { getState }) => {
  try {
    const { data: dataRes } = await BankAdmin.getAllCardBank();
    return {
      method: { ...dataRes.data },
    };
  } catch (error) {
    console.log(error);
  }
});

const bankAdminSlice = createSlice({
  name: 'BankAdmin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankAdmin/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankAdmin/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankAdmin/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bankAdminSlice.reducer;
