import {
  IBankAdmin,
  IBankMethod,
  IDataResListBankAdmin,
  IDataResMethodBank,
  IParamListBankAdmin,
} from '@interfaces/BankAdmin';
import httpRequest from './Api';

const BankAdmin = {
  getAllBankAdmin({ ...rest }: Partial<IParamListBankAdmin>): Promise<IDataResListBankAdmin<IBankAdmin>> {
    const url = '/list-bank-cards';
    return httpRequest.get(url, { params: { ...rest } });
  },
  getAllCardBank(): Promise<IDataResMethodBank<IBankMethod>> {
    const url = '/list-card-type';
    return httpRequest.get(url);
  },
};

export default BankAdmin;
