import React, { useState } from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
export interface IInviteComponentProps {}

export default function InviteComponent(props: IInviteComponentProps) {
  const { data } = useAppSelector((state) => state.authSlice);
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="invite">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-friend">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-solid fa-user-group"></i>Giới thiệu bạn bè
            </span>
          </div>
          {/* <div className="title">Lời mời của bạn </div>
          <div className="achievement">
            <div className="achievement_item">
              Được chấp nhận / Đã hoàn thành<span>0 / 0</span>
            </div>
            <div className="achievement_item">
              Số bạn giới thiệu thành công / Số tiền thưởng
              <span>{`0 / VND 0.00 ('000)`}</span>
            </div>
          </div> */}
          <div className="txt-lightgray">
            Giới Thiệu Bạn Bè, nhận ngay giải thưởng đặc biệt khi rủ bạn cùng chơi bằng đường dẫn/mã code của riêng bạn
          </div>
          <img src={require('@assets/body/invite-step.png')} className="refbanner" alt="refStepBanner" />
          <div className="referralModule">
            {/* <div className="refShare">
              <div className="title">
                Những phần thưởng hấp dẫn đang chờ bạn. Giới thiệu bạn cùng chơi ngay hôm nay!
              </div>
              <div className="refBox">
                <div>
                  <div>
                    Thời gian có hiệu lực<span>30 Ngày</span>
                  </div>
                  <div>
                    Khuyến Mãi<span>{`VND 88.12 ('000)`}</span>
                  </div>
                  <div>
                    Cược hợp lệ<span>{`VND 2,500.00 ('000)`}</span>
                  </div>
                  <div>
                    Nạp tiền<span>{`VND 500.00 ('000)`}</span>
                  </div>
                  <button className="more">XEM CHI TIẾT</button>
                </div>
              </div>
            </div> */}
            <div className="refLink">
              <div className="title">Chia sẻ đường dẫn/mã code của bạn</div>
              <div className="refBox mt-2">
                <div className="refLink_inner" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  Mã giới thiệu :{' '}
                  <span id="ref_link" style={{ width: 'calc(30% - 5px)' }}>
                    {data.invite_code}
                  </span>
                </div>
                <div>
                  <a className="icon icon-files-empty">Sao chép vào bộ nhớ tạm thời</a>

                  <a className="icon icon-download">
                    {' '}
                    <CopyToClipboard text={data.invite_code} onCopy={onCopyText}>
                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}>copy</p>
                        {!isCopied ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-regular fa-circle-check"></i>
                        )}
                      </p>
                    </CopyToClipboard>
                  </a>
                  <a className="icon icon-facebook">Facebook</a>
                  <a className="icon icon-telegram">Telegram</a>
                  <a className="icon icon-whatsapp">WhatsApp</a>
                  <a className="icon icon-line">LINE</a>
                </div>
                <canvas height={160} width={160} id="rqtdqc4z" style={{ height: 128, width: 128, display: 'none' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
