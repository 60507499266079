import { RightOutlined } from '@ant-design/icons';
import { IGameUser } from '@interfaces/GameUser';
import { useState } from 'react';
import './style.scss';

const GameUserList = ({ data = [] }: { data?: IGameUser[] }) => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<IGameUser>();
  const [itemsClose, setItemsClose] = useState<any[]>([]);

  const onToggleClick = (id: any) => {
    const find = itemsClose.find((item) => item === id);
    if (find) {
      setItemsClose((pre) => pre.filter((item) => item !== id));
    } else {
      setItemsClose((pre) => [...pre, id]);
    }
  };

  return (
    <>
      <div className="table-mobile">
        {data.map((item, index) => {
          const isClose = itemsClose.includes(item.id);
          return (
            <div
              key={index}
              className="table-mobile-item"
              onClick={() => {
                onToggleClick(item.id);
              }}
            >
              <img className="item-image" src={item.game.web_pic} />
              <div className="flex-column">
                <b>{item.game.title}</b>
                <span style={{ color: '#555' }}> {item.point} điểm</span>
                {item.username && (
                  <div style={{ display: isClose ? 'none' : 'block' }}>
                    <div style={{ display: 'flex', color: '#666' }}>
                      <b style={{ minWidth: 100 }}>Tên truy cập:</b>
                      <span>{item?.username}</span>
                    </div>
                    <div style={{ display: 'flex', color: '#666' }}>
                      <b style={{ minWidth: 100 }}>Mật khẩu:</b>
                      <span>{item?.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {item.username && (
                <div
                  className="flex-column"
                  style={{
                    marginLeft: 'auto',
                    alignItems: 'end',
                    color: '#999',
                    transform: isClose ? 'unset' : 'rotate(90deg)',
                  }}
                >
                  <RightOutlined />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GameUserList;
