import React from 'react';
import { Pagination, Tabs } from 'antd';
import type { PaginationProps, TabsProps } from 'antd';
import NewsEvent from '../news-event';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllEvent } from '@store/eventSlice';

const TabEventMobile = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.eventSlice);
  const [page, setPage] = React.useState<number>(1);
  const [typeEvent, setTypeEvent] = React.useState<number>(1);
  const onChange = (key: string) => {
    if (key === '0') {
      dispatch(getAllEvent({ page }));
      setTypeEvent(Number(key));
      return;
    }
    dispatch(getAllEvent({ type: Number(key), page }));
    setTypeEvent(Number(key));
  };
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPage(current);
    dispatch(getAllEvent({ type: Number(typeEvent), current }));
  };
  const items: TabsProps['items'] = data.category
    ? [
        {
          key: String(0),
          label: (
            <button className="tab-item">
              <p>Tất cả</p>
            </button>
          ),
          children: (
            <>
              <NewsEvent />
              {!!data.total && (
                <div className="pagination-wrap">
                  <Pagination defaultCurrent={page} total={data.total} onShowSizeChange={onShowSizeChange} />
                </div>
              )}
            </>
          ),
        },
        ...Object.values(data.category).map((item, index) => ({
          key: String(index + 1),
          label: (
            <button className="tab-item">
              <p>{item}</p>
            </button>
          ),
          children: (
            <>
              <NewsEvent />
              {!!data.total && (
                <div className="pagination-wrap">
                  <Pagination defaultCurrent={page} total={data.total} onShowSizeChange={onShowSizeChange} />
                </div>
              )}
            </>
          ),
        })),
      ]
    : [];
  return <Tabs className="tab-event" animated={false} defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default TabEventMobile;
