import * as React from 'react';

export interface ISubfooterProps {}

// @assets/images/vip1.png
export default function Subfooter(props: ISubfooterProps) {
  return (
    <div className="infoCenterMobile">
      <div className="title">THÔNG TIN</div>
      <div className="list-info">
        <a target="_blank" rel="noreferrer" href="/nap-tien">
          <img src={require('@assets/images/infoicon1.png')} alt="CÁCH NẠP TIỀN" />
          <p>CÁCH NẠP TIỀN</p>
        </a>
        <a target="_blank" rel="noreferrer" href="/rut-tien">
          <img src={require('@assets/images/infoicon2.png')} alt="CÁCH RÚT TIỀN" />
          <p>CÁCH RÚT TIỀN</p>
        </a>
        <a target="_blank" rel="noreferrer" href="/hoi-dap">
          <img src={require('@assets/images/infoicon3.png')} alt="HỎI ĐÁP" />
          <p>HỎI ĐÁP</p>
        </a>
      </div>
      <a className="AFFILIATEBtn" target="_blank" rel="noreferrer" href="https://daily.viva88viet.net">
        CHƯƠNG TRÌNH ĐẠI LÝ
      </a>
    </div>
  );
}
