import {
  IBankUser,
  IDataResListBankUser,
  IParamCreateBankUser,
  IParamDeleteBankUser,
  IParamListBankUser,
} from '@interfaces/BankUser';
import httpRequest from './Api';

const BankdUser = {
  getAllBankUser({ ...rest }: Partial<IParamListBankUser>): Promise<IDataResListBankUser<IBankUser>> {
    const url = '/list-user-banks';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: IParamCreateBankUser): Promise<{ messages: string }> {
    const url = '/create-user-bank';
    return httpRequest.post(url, { ...rest });
  },

  delete({ ...rest }: IParamDeleteBankUser): Promise<{ messages: string }> {
    const url = '/delete-user-bank';
    return httpRequest.post(url, { ...rest });
  },
};

export default BankdUser;
