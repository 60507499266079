import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

const initialState: { size: number; openLogin: boolean; typeChat: any } = {
  size: 1920,
  openLogin: false,
  typeChat: 'minimized',
};

const commonSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleResize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    handleOpen: (state, action: PayloadAction<boolean>) => {
      state.openLogin = action.payload;
    },
    handleChangeTypeChat: (state, action: PayloadAction<any>) => {
      state.typeChat = action.payload;
    },
  },
});
export const { handleResize, handleOpen, handleChangeTypeChat } = commonSlice.actions;
export default commonSlice.reducer;
