import { IDataRes, IPoint, IPointParam, IPointParamCreate } from '@interfaces/Point';
import httpRequest from './Api';

const Point = {
  getAllPoint({ ...rest }: Partial<IPointParam>): Promise<IDataRes<IPoint>> {
    const url = '/list-transfer-points';
    return httpRequest.get(url, { params: { ...rest } });
  },

  transactionPoint({ ...rest }: IPointParamCreate): Promise<{ messages: string }> {
    const url = '/create-transfer-point';
    return httpRequest.post(url, { ...rest });
  },
};

export default Point;
