import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import './style.scss';
import NewsList from './news-list';
import { IMenu, IOutstanding } from '@interfaces/Home';
type INewsList = {
  menu: IMenu[] | null;
  outstanding: IOutstanding[];
};
const onChange = (key: string) => {};

const NewsMobile = (props: INewsList) => {
  const { menu, outstanding } = props;
  const tabOutstanding: TabsProps['items'] = [
    {
      key: '1.1',
      label: (
        <button className="tab-item active">
          <img src={require('@assets/images/hot.png')} alt="hotGame" />
          <p>NỔI BẬT</p>
        </button>
      ),
      children: <NewsList data={outstanding} />,
    },
  ];
  const tabMenu: TabsProps['items'] = menu?.map((item, index) => ({
    key: String(index),
    label: (
      <button className="tab-item active">
        <img src={item.icon} alt="hotGame" />
        <p>{item.title}</p>
      </button>
    ),
    children: (
      <NewsList
        data={[
          {
            id: item.id,
            pid: item.pid,
            title: item.title,
            slug: item.slug,
            url: item.url,
            icon: item.icon,
            image: item.image,
            thumbail: item.thumbail,
            description: item.description,
          },
          ...item.child,
        ]}
      />
    ),
  }));

  return (
    <>
      {tabMenu && tabOutstanding && (
        <Tabs className="tab-menu" defaultActiveKey="1.1" items={[...tabOutstanding, ...tabMenu]} onChange={onChange} />
      )}
    </>
  );
};

export default NewsMobile;
