import { IDataRes, IPageItem, IPageParam } from '@interfaces/Detail';
import httpRequest from './Api';

const Detail = {
  getDetailSlug({ ...rest }: Partial<IPageParam>): Promise<IDataRes<IPageItem>> {
    const url = '/detail-page';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default Detail;
