import VisibleAmount from '@components/common/visible';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IMenuMobileProps {
  isCheckLogin: boolean;
}

export default function MenuMobile(props: IMenuMobileProps) {
  const { isCheckLogin } = props;
  return (
    <>
      {isCheckLogin && (
        <div className="layout-full menu-mobile-layout">
          <div className="layout-max menu-mobile-container">
            <div className="walletBox">
              {/* <div className="side-info">
                <p>Số dư</p>
                <VisibleAmount amount={1000} />
              </div> */}
              <div className="content">
                <Link className="item" to="/thanh-vien/nap-tien">
                  <i className="fa-solid fa-building-columns"></i>
                  <span>Nạp tiền</span>
                </Link>
                <Link className="item" to="/thanh-vien/chuyen-diem">
                  <i className="fa-solid fa-dollar-sign"></i>
                  <span>Chuyển điểm</span>
                </Link>
                <Link className="item" to="/thanh-vien/rut-tien">
                  <i className="fa-solid fa-clock-rotate-left"></i>
                  <span>Rút tiền</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
