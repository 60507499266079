import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataLevel, StateLevel } from '@interfaces/Level';
import Level from '@services/Level';

const initialState: StateLevel<IDataLevel> = {
  data: { slide: [], vip: [] },
  loading: false,
  error: '',
};

export const getAllLevel = createAsyncThunk('Level/getAllLevel', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Level.getAllLevel();
    return {
      ...dataRes.data,
    };
  } catch (error) {
    console.log(error);
  }
});

const levelSlice = createSlice({
  name: 'Level',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default levelSlice.reducer;
