import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper';
import { IOutstanding, SubMenu } from '@interfaces/Home';
import ImgErorr from '@assets/images/picture.png';
import { Link } from 'react-router-dom';
type INewsList = {
  data: SubMenu[] | IOutstanding[];
};

export default function NewsList(props: INewsList) {
  const { data } = props;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>`;
    },
  };

  return (
    <>
      <Swiper
        pagination={true}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="list-news" style={{ width: '100%' }}>
            {data.map((item) => (
              <div key={item.id} className="game">
                <Link to={`/tro-choi/${item.slug}`}>
                  <img src={item.image || ImgErorr} alt="lbl_Fishing" />
                  <div className="title">{item.title || 'thông tin'}</div>
                </Link>
              </div>
            ))}
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
