import { Button, Drawer, Space } from 'antd';
import { RadioChangeEventTarget } from 'antd/lib/radio';
import * as React from 'react';
import './style.scss';
import MenuComponent from '../menu';
import { useAppSelector } from '@hook/index';
import { CloseOutlined } from '@ant-design/icons';
import VN from '@assets/images/la_co_vn.png';
import EN from '@assets/images/la_co_en.png';
import { IMenu } from '@interfaces/Home';
export interface INavProps {}

export default function Nav(props: INavProps) {
  const [open, setOpen] = React.useState(false);
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data } = useAppSelector((state) => state.homeSlice);
  const { data: DataAuth } = useAppSelector((state) => state.authSlice);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (size > 768) {
      setOpen(false);
    }
  }, [size]);

  return (
    <>
      <div className="btn-nav" onClick={showDrawer}>
        {' '}
        <i className="fa-solid fa-bars"></i>
      </div>
      <Drawer
        className="nav-help-wrapper"
        bodyStyle={{ padding: 0 }}
        width={'100%'}
        placement={'left'}
        onClose={onClose}
        closable={false}
        open={open}
      >
        <div className="menu-body-container">
          <MenuComponent onClose={onClose} menu={data.header?.menu} />
        </div>
      </Drawer>
    </>
  );
}
