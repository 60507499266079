import * as React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getSetting } from '@store/settingSlice';

export interface IInfoProps {}

export default function Info(props: IInfoProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.homeSlice);

  return (
    <div className="layout-full info">
      <div className="layout-max info-container">
        <div className="indexInfo">
          <div className="infoCenter">
            <div>
              <div className="title-main">
                <i className="icon icon-infocenter" />
                THÔNG TIN
              </div>
              <div className="infoGroup">
                <a href="/nap-tien" rel="noreferrer" target="_blank">
                  <i className="icon icon-deposit2" />
                  CÁCH NẠP TIỀN
                </a>
                <a href="/rut-tien" rel="noreferrer" target="_blank">
                  <i className="icon icon-withdrawal2" />
                  CÁCH RÚT TIỀN
                </a>
                <a href="/hoi-dap" rel="noreferrer" target="_blank">
                  <i className="icon icon-support" />
                  HỎI ĐÁP
                </a>
              </div>
            </div>
          </div>
          <div className="news">
            <div>
              <div className="title-main">
                <i className="icon icon-newspaper" />
                Điểm báo Viva88Viet
              </div>
            </div>
            <div className="articleModule">
              {data.body.posts.length
                ? data.body.posts?.map((el) => {
                    let linkPost = '/bai-viet/' + el?.slug;
                    return (
                      <React.Fragment key={el?.slug}>
                        <Link to={linkPost}>
                          <img src={el?.thumbail} alt={el?.title} />
                          <div className="title">
                            <p>{el?.title}</p>
                          </div>
                        </Link>
                      </React.Fragment>
                    );
                  })
                : ''}
            </div>
            <div className="btnall">
              <Link to="/tin-tuc">Xem Thêm</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
