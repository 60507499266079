import { IDataPointPrice, StatePointPrice } from '@interfaces/PointPrice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PointPrice from '@services/PointPrice';

const initialState: StatePointPrice<IDataPointPrice> = {
  data: {
    listPointPrice: [],
  },
  loading: false,
  error: '',
};

export const getPointPrice = createAsyncThunk('PointPrice/getPointPrice', async (_, { getState }) => {
  try {
    const { data: dataRes } = await PointPrice.getPointPrice();
	return {
		listPointPrice: [...dataRes.data],
	};
  } catch (error) {
    console.log(error);
  }
});

const pointPriceSlice = createSlice({
  name: 'PointPrice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default pointPriceSlice.reducer;
