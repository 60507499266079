import React, { useEffect, useState } from 'react';
import Logo from '@assets/images/logo-main.png';

import { Badge, Popover, notification } from 'antd';
import PopoverArow from '@components/common/popover-arow';
import ModalBasic from '@components/layout/modal';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getHeader } from '@store/homeSlice';
import Nav from './nav';
import { Link } from 'react-router-dom';

export interface IHeaderComponentProps {}

export default function HeaderComponent(props: IHeaderComponentProps) {
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  return (
    <div className="layout-full header-main-layout">
      <div className="layout-max-help header-container">
        <div className="header-container__left">
          <Nav />
          <Link to={'/'}>
            <div className="logo-img">
              {' '}
              <img src={DataHome.header.logo} alt="logo trang chủ" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
