import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import './style.scss';
import { useAppSelector } from '@hook/index';
import { IMenu } from '@interfaces/Home';
import { Link, useNavigate } from 'react-router-dom';
type MenuItem = Required<MenuProps>['items'][number];
function getItem(label: React.ReactNode, key: React.Key, children?: MenuItem[], type?: 'group'): MenuItem {
  return {
    key,
    children,
    label,
    type,
  } as MenuItem;
}

// submenu keys of first level
const rootSubmenuKeys = [''];
type IMenuComponent = {
  menu: IMenu[] | null;
  handleClose: () => void;
};
const MenuComponent = ({ menu, handleClose }: IMenuComponent) => {
  let navigate = useNavigate();
  const handleChangeLink = (event, slug: string) => {
    event.stopPropagation();
    handleClose();
    navigate(`/tro-choi/${slug}`);
  };
  const items: MenuItem[] = [
    getItem(
      <Link to="/khuyen-mai" onClick={() => handleClose()}>
        <div className="menu-main-title title-single">
          <i className="fa-solid fa-gift"></i>
          KHUYẾN MÃI
        </div>
      </Link>,
      'sub9',
    ),
    getItem(
      <Link to="/cap-bac" onClick={() => handleClose()}>
        <div className="menu-main-title title-single">
          <div className="menu-item">VIP</div>
        </div>
      </Link>,
      'sub11',
    ),
  ];
  const menuItem = menu?.map((item) => {
    if (item.child.length > 0) {
      return getItem(
        <div className="menu-main-title">
          {/* {item.icon} */}
          <div onClick={(e) => handleChangeLink(e, item.slug)}> {item.title}</div>
        </div>,
        String(item.id),
        item.child.map((subItem) =>
          getItem(
            <Link onClick={handleClose} to={`/tro-choi/${subItem.slug}`}>
              {subItem.title}
            </Link>,
            item.id + '-' + subItem.id,
          ),
        ),
      );
    }
    return getItem(
      <div className="menu-main-title title-single">
        {/* {item.icon} */}
        <Link onClick={handleClose} to={`/tro-choi/${item.slug}`}>
          {item.title}
        </Link>
      </div>,
      String(item.id),
    );
  });
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      {menuItem && (
        <Menu
          className="menu-mobile"
          mode="inline"
          onOpenChange={onOpenChange}
          style={{ width: '100%' }}
          items={[...menuItem, ...items]}
          selectable={false}
        />
      )}
    </>
  );
};

export default MenuComponent;
