import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Category from '@services/Category';
import {
  ICategoryParam,
  IDataCategory,
  IParamCategoryDetail,
  IParamListPost,
  StateCategory,
} from '@interfaces/Category';

const initialState: StateCategory<IDataCategory> = {
  data: {
    listInfo: [],
    listPost: [],
    infoDetail: null,
    current_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllCategory = createAsyncThunk(
  'Category/getAllCategory',
  async (payload: Partial<ICategoryParam>, { getState }) => {
    try {
      const { data: dataRes } = await Category.getAllCategory({ ...payload });
      return {
        listInfo: [...dataRes.data],
        current_page: dataRes.current_page,
        total: dataRes.total,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllPost = createAsyncThunk(
  'Category/getAllPost',
  async (payload: Partial<IParamListPost>, { getState }) => {
    try {
      const { data: dataRes } = await Category.getPostList({ ...payload });
      return {
        listInfo: [...dataRes.data],
        current_page: dataRes.current_page,
        total: dataRes.total,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getCategoryDetail = createAsyncThunk(
  'Category/getCategoryDetail',
  async (payload: Partial<IParamCategoryDetail>, { getState }) => {
    try {
      const { data: dataRes } = await Category.getCategoryId({ ...payload });
      return {
        infoDetail: { ...dataRes },
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getPostDetail = createAsyncThunk(
  'Category/getpostDetail',
  async (payload: Partial<IParamCategoryDetail>, { getState }) => {
    try {
      const { data: dataRes } = await Category.getPostId({ ...payload });
      return {
        infoDetail: { ...dataRes },
      };
    } catch (error) {
      console.log(error);
    }
  },
);
// export const getCategory = createAsyncThunk('Category/getCategory', async (_, { getState }) => {
//   try {
//     const { data: dataRes } = await Category.getcategory();
//     return {
//       category: { ...dataRes.data },
//     };
//   } catch (error) {
//     console.log(error);
//   }
// });

const categorySlice = createSlice({
  name: 'Category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default categorySlice.reducer;
