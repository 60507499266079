import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDataTransfer, IParamListDataTransfer, ITransferDeposit, StateDataTransfer } from '@interfaces/Transfer';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Transfer from '@services/Transfer';

const initialState: StateDataTransfer<IDataTransfer> = {
  data: {
    listTransfer: [],
    current_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getTransfer = createAsyncThunk(
  'Transfer/getAllTransfer',
  async (payload: Partial<IParamListDataTransfer>, { getState }) => {
    try {
      const { data: dataRes } = await Transfer.getAllTransfer({ ...payload });
      return {
        listTransfer: [...dataRes.data],
        current_page: dataRes.current_page,
        total: dataRes.total,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const deposit = createAsyncThunk(
  'Transfer/deposit',
  async (payload: PayloadCallBack<ITransferDeposit>, { getState }) => {
    try {
      delete payload.param.money;
      const { messages } = await Transfer.deposit({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload?.error?.(error?.response?.data.messages);
    }
  },
);

const TransferSlice = createSlice({
  name: 'Transfer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Transfer/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Transfer/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Transfer/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default TransferSlice.reducer;
