import { IDataCallback, PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateUser, IForgotFassword, IUpdateUser, IUserInfo } from '@interfaces/User';
import { IUserLogin, StateAuth } from '@interfaces/UserInterface';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Auth, { IReqPassword } from '@services/Auth';
import { isPlainObject } from 'lodash';
import Cookies from 'universal-cookie';

let localUser;
try {
  const cookies = new Cookies();

  const token = cookies.get('userToken') as string;
  const userData = cookies.get('userData');
  if (isPlainObject(userData)) {
    localUser = { ...userData, accessToken: token, isLogin: true };
  }
} catch (error) {}
const initialState: StateAuth<IUserInfo> = {
  data: localUser || {
    isLogin: false,
  },

  loading: false,
  error: '',
};

export const authLogin = createAsyncThunk('auth/login', async (payload: IDataCallback<IUserLogin>, { getState }) => {
  try {
    const res = await Auth.login(payload.data);
    if (res.messagse === 'Invalid credentials') {
      payload.error();
      return { accessToken: res.access_token || '', isLogin: false };
    }
    const cookies = new Cookies();

    cookies.set('userToken', res.access_token || '', {
      httpOnly: false,
      sameSite: 'lax',
      // expires: new Date(res.expires_at),
      // maxAge: res.expires_in, // 10800s to 3 house
    });
    payload.callback();
    return { accessToken: res.access_token || '', isLogin: true };
  } catch (error: any) {
    payload.error(error?.response?.data?.messages);
    console.log('error', error);
    return { accessToken: '', isLogin: false };
  }
});

export const authLogout = createAsyncThunk(
  'auth/logout',
  async (payload: { isLogin: boolean; redirectLogout: () => void }, { getState }) => {
    try {
      await Auth.logout();
      const cookies = new Cookies();
      cookies.remove('userToken');
      cookies.remove('userData');
      payload.redirectLogout();
      return { id: 0, email: '', status: 0, isLogin: false, accessToken: '' };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getCurrentUser = createAsyncThunk(
  'auth/getUserCurrent',
  async (paylod: { callback: () => void }, { getState }) => {
    try {
      const res = await Auth.getUserCurrent();
      paylod.callback();
      return { ...res.data.data, isLogin: true };
    } catch (error) {
      const cookies = new Cookies();
      cookies.remove('userToken');
      cookies.remove('userData');
      paylod.callback();
      console.log(error);
    }
  },
);

export const creatUser = createAsyncThunk(
  'auth/regiser',
  async (payload: PayloadCallBack<ICreateUser>, { getState }) => {
    try {
      const { messages } = await Auth.register({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      payload.callback(error?.response?.data?.messages);
      console.log(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  'auth/update',
  async (payload: PayloadCallBack<IUpdateUser>, { getState }) => {
    try {
      const { messages } = await Auth.update({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);
export const changePass = createAsyncThunk(
  'auth/pass',
  async (payload: PayloadCallBack<IReqPassword>, { getState }) => {
    try {
      const { messages } = await Auth.changePassword({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);

export const forgotFassword = createAsyncThunk(
  'auth/forgot-pass',
  async (payload: PayloadCallBack<IForgotFassword>, { getState }) => {
    try {
      const { messages } = await Auth.forgotFassword({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);

export const getOTP = createAsyncThunk(
  'auth/getOTP',
  async (payload: PayloadCallBack<{ username: string }>, { getState }) => {
    try {
      const { messages } = await Auth.getOTP({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          if (action.type === 'user/logout/pending') return;
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { handleLoading } = authSlice.actions;
export default authSlice.reducer;
