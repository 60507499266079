import httpRequest from './Api';
import { IDataRes, IDataResBody, IDataResFooter, IMenu } from '@interfaces/Home';

const Home = {
  getHeader(): Promise<IDataRes<IMenu>> {
    const url = '/header';
    return httpRequest.get(url);
  },

  getBody(): Promise<IDataResBody> {
    const url = '/body';
    return httpRequest.get(url);
  },
  getFooter(): Promise<IDataResFooter> {
    const url = '/footer';
    return httpRequest.get(url);
  },
};

export default Home;
