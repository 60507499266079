import * as React from 'react';

export interface IOtherComponentProps {}
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { Link } from 'react-router-dom';
import { getFooter } from '@store/homeSlice';
export default function OtherComponent(props: IOtherComponentProps) {
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getFooter());
  }, []);
  return (
    <div className="other-page">
      <div className="webmap">
        <div>
          <span>Chính sách của chúng tôi</span>
          {DataHome.footer?.policy.map((item, index) => {
            return (
              <Link key={index} to={`/${item.slug}`}>
                {item.title}
              </Link>
            );
          })}
        </div>
        <div>
          <span>Hỗ trợ</span>
          {DataHome.footer?.suport.map((item, index) => {
            return (
              <Link key={index} to={`/${item.slug}`}>
                {item.title}
              </Link>
            );
          })}
        </div>
        <div>
          <span>Viva88Viet</span>
          <Link to={`https://daily.viva88viet.net`}>
            {' '}
            <span style={{ fontWeight: 'bold', color: '#052561' }}>Đại lí</span>
          </Link>
          {DataHome.footer?.agent.map((item, index) => {
            return (
              <Link key={index} to={`/${item.slug}`}>
                {item.title}
              </Link>
            );
          })}
        </div>
        <div className="list-partner">
          <div className="partner-bar">
            <img alt="logo" src={require('@assets/footer/platform/saba.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sbo.png')} />

            <img alt="logo" src={require('@assets/footer/platform/ag.png')} />

            <img alt="logo" src={require('@assets/footer/platform/ae.png')} />

            <img alt="logo" src={require('@assets/footer/platform/n2live.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pp.png')} />

            <img alt="logo" src={require('@assets/footer/platform/bg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yb.png')} />

            <img alt="logo" src={require('@assets/footer/platform/mg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pt.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/cq9.png')} />

            <img alt="logo" src={require('@assets/footer/platform/jili.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yl.png')} />

            <img alt="logo" src={require('@assets/footer/platform/v8.png')} />

            <img alt="logo" src={require('@assets/footer/platform/kingmaker.png')} />

            <img alt="logo" src={require('@assets/footer/platform/tcg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/rt.png')} />

            <img alt="logo" src={require('@assets/footer/platform/evolution.png')} />

            <img alt="logo" src={require('@assets/footer/platform/habanero.png')} />

            <img alt="logo" src={require('@assets/footer/platform/simpleplay.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sk.png')} />
          </div>
        </div>
      </div>
    </div>
  );
}
