import * as React from 'react';
import './style.scss';
import { Pagination } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllCategory, getAllPost } from '@store/categorySlice';
import { Link, useLocation } from 'react-router-dom';
export interface IListPostComponentProps {}

export default function ListPostComponent(props: IListPostComponentProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.categorySlice);
  let location = useLocation();
  React.useEffect(() => {
    if (!location) return;
    dispatch(
      getAllPost({
        category_id: location.state?.id,
        page: 1,
        paginate: 15,
      }),
    );
  }, [location]);
  const ChangePage = (pageItem: number, pageSize: number) => {
    dispatch(
      getAllPost({
        category_id: location.state?.id,
        page: pageItem,
        paginate: 15,
      }),
    );
  };
  return (
    <div className="layout-full news">
      <div className="layout-max background-event">
        <div className="rightModule">
          <div className="infoModule">
            <div className="inside-title icon icon-newspaper">
              <span>{location.state?.title || 'Danh muc'}</span>
            </div>
            <div className="newsList">
              {data.listInfo.map((item) => (
                <div key={item.id}>
                  <img src={item.thumbail} alt={item.title} />
                  <div className="txt-darkBlue">{item.title}</div>
                  <div className="txt-gray">{item.description}</div>
                  <div className="btnGroup">
                    <Link className="blue" to={`/bai-viet/${item.slug}`}>
                      Chi tiết
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination-container">
              <Pagination
                current={data.current_page}
                onChange={ChangePage}
                total={Number(data.total)}
                defaultPageSize={15}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
