import * as React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
export interface INotFoundProps {}

export default function NotFound(props: INotFoundProps) {
  return (
    <div className="notfound">
      <div className="code-error">404</div>
      <Link to="/">Quay lại trang chủ</Link>
    </div>
  );
}
