import * as React from 'react';
import './style.scss';
import { SubMenu } from '@interfaces/Home';
export interface IGameItemProps {
  child?: SubMenu;
}

export default function GameItem(props: IGameItemProps) {
  const { child } = props;
  return (
    <button className="game-item">
      <img className="item-bg" src={child?.image} alt={child?.title} />

      <span>
        <p>{child?.title}</p>
      </span>
    </button>
  );
}
