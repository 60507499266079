import { IDataLevel } from '@interfaces/Level';
import { moneyInput } from '@utils/convert';
import * as React from 'react';

export interface ITbaleLevelProps {
  data: IDataLevel;
}

export default function TbaleLevel(props: ITbaleLevelProps) {
  const { data } = props;
  return (
    <>
      <div className="layout-full">
        <div className="layout-max">
          <table className="medal">
            <tbody>
              <tr>
                <td>CẬP NHẬT GẦN NHẤT ： 10/25/2022</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>
                    <img src={item.image} alt="vip01" />
                    {item.level_name}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <table className="eventList">
            <tbody>
              <tr className="title">
                <td className="icon icon-nova88">
                  ƯU ĐÃI RIÊNG HẤP DẪN <span className="txt-orange">(VND)</span>
                </td>
                <td colSpan={11} />
              </tr>
              <tr>
                <td>Hạng mức rút tiền trong ngày</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.withdrawal_today)}</td>
                ))}
              </tr>
              <tr>
                <td>Số tiền nộp</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.deposit_money)}</td>
                ))}
              </tr>
              <tr>
                <td>Phần thưởng theo hạng</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.level_bonus)}</td>
                ))}
              </tr>
              <tr>
                <td>Phần thưởng theo ngày</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.day_bonus)}</td>
                ))}
              </tr>
              <tr>
                <td>Phần thưởng theo tuần</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.week_bonus)}</td>
                ))}
              </tr>
              <tr>
                <td>Phần thưởng theo tháng</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.month_bonus)}</td>
                ))}
              </tr>
              <tr>
                <td>Phần thưởng theo năm</td>
                {data.vip.map((item) => (
                  <td key={item.level_name}>{moneyInput(item.year_bonus)}</td>
                ))}
              </tr>
            </tbody>
          </table>
          <img className="event-link" src={data?.banner?.url} alt="vip-program-vn" />
        </div>
      </div>
    </>
  );
}
