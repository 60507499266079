import * as React from 'react';
import './style.scss';
import Imgmaintenance from '@assets/images/maintenance.jpg';
export interface IMaintenanceProps {}

export default function Maintenance(props: IMaintenanceProps) {
  return (
    <div className="maintenance">
      <div className="content">
        Hệ thống đang được bảo trì <br /> Vui lòng truy cập vào sau
      </div>
    </div>
  );
}
