import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDataInvite, StateInvite } from '@interfaces/Invite';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Invite from '@services/Invite';

const initialState: StateInvite<IDataInvite> = {
  data: {
    listInvite: [],
  },
  loading: false,
  error: '',
};

export const getInvite = createAsyncThunk('Invite/getAllInvite', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Invite.getAllInvite();
    return {
      listInvite: [...dataRes.data],
    };
  } catch (error) {
    console.log(error);
  }
});

const inviteSlice = createSlice({
  name: 'Invite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Invite/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Invite/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Invite/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default inviteSlice.reducer;
