import * as React from 'react';
import './style.scss';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import ModalBasic from './modal';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { convert } from '@utils/convert';
import { ITransferWithdraw } from '@interfaces/Transfer';
import { deposit } from '@store/transferSlice';
import showMessage from '@utils/helper';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
export interface IWithdrawComponentProps {}

export default function WithdrawComponent(props: IWithdrawComponentProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataSetting } = useAppSelector((state) => state.settingSlice);
  const [bankKey, setBankKey] = React.useState<number>(0);
  const [param, setParam] = React.useState<ITransferWithdraw>();
  const { data: DataGame } = useAppSelector((state) => state.gameSlice);
  const { data: DataBankUser } = useAppSelector((state) => state.bankUserSlice);
  const MySwal = withReactContent(Swal);
  const [isWithdraw, setIsWithdraw] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (!data?.is_withdraw || data?.is_withdraw === 0) {
      MySwal.fire({
        title: DataSetting?.listSetting?.reason_withdrawal || `Lỗi không xác định`,
        customClass: { confirmButton: 'btn-confirm' },
      });
      setIsWithdraw(false);
    } else {
      setIsWithdraw(true);
    }
  }, [data]);
  const handleResetFile = () => {
    form.resetFields();
  };
  const handlerChangeBank = (index: number) => {
    setBankKey(index);
  };
  const handleChangePage = () => {
    navigate('/thanh-vien/lich-su-rut-tien');
  };

  const handleChangePageBank = () => {
    navigate('/thanh-vien/thong-tin-ngan-hang');
  };
  const handleSubmit = (event: ITransferWithdraw) => {
    setParam({ ...param, ...event, transfer_type: 1, bank_id: DataBankUser.listBank[bankKey]?.id });
    const pointValue = parseFloat(parseFloat(event.point.toString().replace(/,/g, '')).toFixed(2));
    dispatch(
      deposit({
        param: {
          ...param,
          ...event,
          transfer_type: 1,
          bank_id: DataBankUser.listBank[bankKey]?.id,
          point: pointValue,
        },
        callback: (messages) => {
          MySwal.fire({
            title: messages,
            customClass: { confirmButton: 'btn-confirm' },
            confirmButtonText: <span onClick={handleChangePage}>Lịch sử rút tiền</span>,
          });
          handleResetFile();
        },
        error: (messages) => {
          MySwal.fire({
            title: messages,
            customClass: { confirmButton: 'btn-confirm' },
            confirmButtonText: <span onClick={handleChangePageBank}>Thông tin ngân hàng</span>,
          });
          handleResetFile();
        },
      }),
    );
  };
  const handleChange = (event) => {
    if (event.money) {
      const money = event.money.toString().replace(/,/g, '');
      form.setFieldsValue({
        ...event,
        money: convert.moneyInputs(money),
        point: convert.numWithCommas(money.replace(/\./g, '').replace(',', '') / Number(data?.point_price)),
      });
    } else if (event.point) {
      const point = event.point.toString().replace(/,/g, '');
      form.setFieldsValue({
        ...event,
        money: convert.moneyInputs(point * Number(data?.point_price)),
        point: convert.numWithCommas(point),
      });
    }
  };
  return (
    <div className="withdraw">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-icon32px_Withdraw">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-solid fa-hand-holding-dollar"></i>Rút tiền
            </span>
            <div>Vui lòng cập nhật thông tin tài khoản ngân hàng của bạn trước khi rút tiền.</div>
          </div>
          <div className="infoModuleLeft">
            <div className="walletWrapper">
              <div className="walletModule">
                {DataBankUser.listBank.map((item, index) => (
                  <div
                    className={`bank-item ${bankKey === index ? 'active' : ''}`}
                    key={item.id}
                    onClick={() => handlerChangeBank(index)}
                  >
                    <img src={item.bank.thumbail} alt="" />
                    <span>Chủ TK :{item.owner_name}</span>
                    <span>Số TK :{item.card_no}</span>
                  </div>
                ))}
              </div>

              <div className="subModuleList">
                <div className="subModuleTitle">
                  <span>
                    <i className="txt-blue">*</i>
                    {`Số tiền (Đơn vị là 1 = ${convert.moneyInput(data?.point_price)} VNĐ). `}
                    {`Vui lòng nhập số điểm hoặc số tiền là số chẵn`}
                  </span>
                </div>
                <div className="subModule">
                  <div className="subModuleBody">
                    <div className="inputModuleRow">
                      <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        onFinish={handleSubmit}
                        onValuesChange={handleChange}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                      >
                        <Row gutter={10} className="row-form" justify="start">
                          <Col span={12}>
                            <Form.Item
                              label="Chuyển điểm"
                              name="point"
                              rules={[
                                { required: true, message: 'Vui lòng nhập số điểm' },
                                {
                                  message: 'Số điểm phải lớn hơn hoặc bằng 1',
                                  validator: (_, value) => {
                                    if (
                                      Number(
                                        parseInt((value === '' ? 0 : value).replace(/\./g, '').replace(',', '')),
                                      ) >= 1
                                    ) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject('Số điểm phải lớn hơn hoặc bằng 1');
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input placeholder="Nhập số điểm" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Số tiền"
                              name="money"
                              rules={[
                                { required: true, message: 'Vui lòng nhập số tiền' },
                                {
                                  message: 'Số tiền phải lớn hơn hoặc bằng ' + convert.moneyInput(data?.point_price),
                                  validator: (_, value) => {
                                    if (
                                      Number(parseInt((value === '' ? 0 : value).toString().replace(/,/g, ''))) >=
                                      Number(data?.point_price)
                                    ) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        `Số tiền phải lớn hơn hoặc bằng ${convert.moneyInput(data?.point_price)}`,
                                      );
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input placeholder="Nhập số tiền" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Col span={12}>
                          <Form.Item
                            label="Trò chơi"
                            name="game_id"
                            rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder="Chọn trò chơi"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={DataGame.listGame?.map((item) => ({ value: item.id, label: item.title }))}
                            />
                          </Form.Item>
                        </Col>
                        {isWithdraw && (
                          <div className="btnGroup">
                            <Col span={12}>
                              <Button htmlType="submit" className="btn-check">
                                Rút tiền
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
