import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getCategoryDetail, getPostDetail } from '@store/categorySlice';
import { useLocation } from 'react-router-dom';
export interface IPostDetailComponentProps {}

export default function PostDetailComponent(props: IPostDetailComponentProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.categorySlice);
  let location = useLocation();
  React.useEffect(() => {
    if (!location) return;
    dispatch(getPostDetail({ slug: location.pathname.split('/')[location.pathname.split('/').length - 1] }));
  }, [location]);
  return (
    <div className="layout-full news-detail">
      <div className="layout-max background-event">
        <div className="rightModule">
          <div className="infoModule">
            <article>
              <img alt={data.infoDetail?.title} src={data.infoDetail?.image} />
              <h1>{data.infoDetail?.title}</h1>
              {/* <pre>{data.infoDetail?.content}</pre> */}
              {React.createElement('pre', { dangerouslySetInnerHTML: { __html: data.infoDetail?.content } })}
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}
