import { useAppSelector } from '@hook/index';
import * as React from 'react';
import './style.scss';
export interface IInfoUserProps {}

export default function InfoUser(props: IInfoUserProps) {
  const { loading, data } = useAppSelector((state) => state.authSlice);
  return (
    <div className="wrapper-info">
      <div className="heade-info">Thông tin cá nhân</div>
      <div className="body-info">
        <div className="body-info__field-item">
          <div className="field-title">Tên người dung</div>
          <div className="field-content">{data.username}</div>
        </div>
        <div className="body-info__field-item">
          <div className="field-title">Tạo IP</div>
          <div className="field-content">192.168.1.1</div>
        </div>
        <div className="body-info__field-item">
          <div className="field-title">Trạng thái</div>
          <div className="field-content">{data.status}</div>
        </div>
      </div>
    </div>
  );
}
