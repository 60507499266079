import Loading from '@components/common/loading';
import { Modal } from 'antd';
import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getEventDetail } from '@store/eventSlice';
import { IEvent } from '@interfaces/Event';

import { useNavigate } from 'react-router-dom';
import { handleChangeTypeChat } from '@store/commonSlice';
export interface IModalEventDetailProps {
  children: React.ReactNode;
  data: IEvent;
  handleCheckLogin: any;
}

export default function ModalEventDetail(props: IModalEventDetailProps) {
  const { children, data, handleCheckLogin } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [visibility, setVisibility] = React.useState<any>('minimized');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleShow = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(handleChangeTypeChat('minimized'));
  };
  const handleNewEvent = (event: any) => {};

  return (
    <>
      <button onClick={handleShow} className="btn-check">
        {children}
      </button>
      <Modal
        title="Khuyển mãi"
        className="modal-container-event"
        zIndex={900}
        closable={true}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="promotionsInfo">
          <img src={data?.cover_image} alt="promotionDetailData_banner" />
          <div className="proData">
            <div className="proDataTitle">
              <div className="txt-blue2">
                {data?.title}
                <div className="txt-blue4"> {data?.rule_content}</div>
              </div>

              {!(data.apply_type === 1) && (
                <button onClick={handleCheckLogin} className="yellow">
                  THAM GIA NGAY
                </button>
              )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: data?.content || '' }} />
          </div>
        </div>
      </Modal>
    </>
  );
}
