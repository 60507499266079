import { useAppDispatch, useAppSelector } from '@hook/index';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import HeaderComponent from './Header';
import './style.scss';
import { getCurrentUser } from '@store/authSlice';
import { handleChangeTypeChat, handleResize } from '@store/commonSlice';
import { getHeader } from '@store/homeSlice';
import Loading from '@components/common/loading';
import { getSetting } from '@store/settingSlice';
import Maintenance from '@components/maintenance';
import { EventHandlerPayload, LiveChatWidget } from '@livechat/widget-react';
type Props = {};

const HelperLayout: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const { Header, Content } = Layout;
  const callback = () => {};
  useEffect(() => {
    dispatch(getCurrentUser({ callback }));
  }, []);
  useEffect(() => {
    dispatch(handleResize(window.innerWidth));
    const setSize = () => {
      dispatch(handleResize(window.innerWidth));
    };
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);
  useEffect(() => {
    dispatch(getHeader());
    dispatch(getSetting());
  }, []);
  const handleHide = ({ visibility }) => {
    dispatch(handleChangeTypeChat(visibility));
  };
  if (!dataSetting.listSetting)
    return (
      <>
        {' '}
        <Loading loadingDefault={true} />
      </>
    );

  if (!Number(dataSetting.listSetting?.web_status)) {
    return (
      <>
        <Maintenance />
      </>
    );
  }
  return (
    <>
      <Loading />
      <Layout className="layout-helper">
        <LiveChatWidget license="13227897" visibility={'minimized'} />
        <HeaderComponent />
        <Layout>
          <Content className="content-help">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default HelperLayout;
