import GameItem from '@components/common/game-item';
import PopoverArow from '@components/common/popover-arow';
import { useAppSelector } from '@hook/index';
import { SubMenu } from '@interfaces/Home';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IMenuheaderProps {}

export default function Menuheader(props: IMenuheaderProps) {
  const [content, setContent] = React.useState<SubMenu[]>([]);
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  const itemMenu = () => (
    <div className="list-game">
      {content?.map((item) => (
        <Link key={item.id} to={`/tro-choi/${item?.slug}`}>
          <GameItem child={item} />
        </Link>
      ))}
    </div>
  );
  const changeBackground = (tx: SubMenu[]) => {
    setContent(tx);
  };
  return (
    <div className="layout-full menu-main-layout">
      <div className="layout-max menu-container">
        <div className="list-menu">
          <Link to="/">
            {' '}
            <div className="menu-item home-icon">
              <i className="fa-solid fa-house"></i>
            </div>
          </Link>

          <div className="">
            <PopoverArow
              content={itemMenu}
              allowArow={false}
              removeArrowIcon={true}
              classCustom="modal-submenu"
              trigger="hover"
            >
              <div className="list-submenu">
                {DataHome.header.menu?.map((menuItem) => (
                  <div key={menuItem.id} onMouseOver={() => changeBackground(menuItem.child)} className="menu-item">
                    <Link to={`/tro-choi/${menuItem?.slug}`}>
                      {menuItem.title}
                      {menuItem.child.length > 0 && <i className="fa-solid fa-chevron-down"></i>}
                    </Link>
                  </div>
                ))}
              </div>
            </PopoverArow>
          </div>
          <Link to="/cap-bac">
            <div className="menu-item">Vip</div>
          </Link>
          <Link to="/khuyen-mai">
            <div className="menu-item">Khuyến mãi</div>
          </Link>

          <div className="menu-item">Đại lí</div>
        </div>
      </div>
    </div>
  );
}
