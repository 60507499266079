import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataGame, IParamGame, StateGame } from '@interfaces/Game';
import Game from '@services/Game';

const initialState: StateGame<IDataGame> = {
  data: {
    listGame: [],
  },
  loading: false,
  error: '',
};

export const getGame = createAsyncThunk('Game/getAllGame', async (payload: Partial<IParamGame>, { getState }) => {
  try {
    const { data: dataRes } = await Game.getAllGame({ ...payload });
    return {
      listGame: [...dataRes],
    };
  } catch (error) {
    console.log(error);
  }
});

const gameSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default gameSlice.reducer;
