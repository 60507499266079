import {
  ICategory,
  IDataRes,
  IDataResCategory,
  IDataResEventDetail,
  IDataResList,
  IEvent,
  IEventDetail,
  IEventParam,
} from '@interfaces/Event';
import httpRequest from './Api';

const Event = {
  getAllEvent({ ...rest }: Partial<IEventParam>): Promise<IDataRes<IEvent>> {
    const url = '/promotion';
    return httpRequest.get(url, { params: { ...rest } });
  },

  getAllList(): Promise<IDataResList<IEvent>> {
    const url = '/promotion';
    return httpRequest.get(url);
  },
  getcategory(): Promise<IDataResCategory<ICategory>> {
    const url = '/category-promotion';
    return httpRequest.get(url);
  },
  getEventId({ ...rest }: Partial<IEventDetail>): Promise<IDataResEventDetail<IEvent>> {
    const url = '/detail-event';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default Event;
