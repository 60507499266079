import { useAppSelector } from '@hook/index';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export interface ISEOProps {
  data?: any;
}

export default function SEO({ data }: ISEOProps) {
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const location = useLocation();
  const [canonical, setCanonical] = React.useState<string>(window.location.href);

  React.useEffect(() => {
    const fullURL = window.location.origin + location.pathname + location.search;
    setCanonical(fullURL);
  }, [location]);

  return (
    <Helmet>
      <title>{data?.seo_title || dataSetting?.listSetting?.seo_title || 'VIVA88VIET'}</title>
      <meta
        name="description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'VIVA88VIET'}
      />
      <meta
        name="keywords"
        content={data?.tags || data?.seo_keywords || dataSetting?.listSetting?.seo_keywords || 'VIVA88VIET'}
      />
      <meta property="og:title" content={data?.seo_title || dataSetting?.listSetting?.seo_title || 'VIVA88VIET'} />
      <meta
        property="og:description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'VIVA88VIET'}
      />
      <meta
        property="og:image"
        content={data?.image || data?.thumbail || data?.icon || dataSetting?.listSetting?.logo || ''}
      />
      <meta property="og:url" content={canonical} />

      <meta name="twitter:title" content={data?.seo_title || dataSetting?.listSetting?.seo_title || 'VIVA88VIET'} />
      <meta
        name="twitter:description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'VIVA88VIET'}
      />
      <meta
        name="twitter:image"
        content={data?.image || data?.thumbail || data?.icon || dataSetting?.listSetting?.logo || ''}
      />
      <meta name="twitter:url" content={canonical} />
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
}
