import * as React from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
export interface LoadingProps {
  sx?: string;
  loadingDefault?: boolean;
}

export default function Loading({ sx, loadingDefault }: LoadingProps) {
  const { loading: loadingAuth } = useAppSelector((state) => state.authSlice);
  const { loading: loadingHome } = useAppSelector((state) => state.homeSlice);
  const { loading: loadingTransfer } = useAppSelector((state) => state.transferSlice);
  const { loading: loadingBankAdmin } = useAppSelector((state) => state.bankAdminSlice);
  const { loading: loadingBankUser } = useAppSelector((state) => state.bankUserSlice);
  const { loading: loadingPoint } = useAppSelector((state) => state.pointSlice);
  const { loading: loadingCategorySlice } = useAppSelector((state) => state.categorySlice);
  const { loading: loadingGetGameDetail } = useAppSelector((state) => state.getGameDetail);

  const loading =
    loadingCategorySlice ||
    loadingAuth ||
    loadingHome ||
    loadingTransfer ||
    loadingBankAdmin ||
    loadingBankUser ||
    loadingPoint ||
    loadingGetGameDetail;

  //   const metaTags = [
  //     'description',
  //     'keywords',
  //     'theme-color',
  //     'author',
  //     'copyright',
  //     'publisher',
  //     'canonical',
  //     'shortcut icon',
  //     'icon',
  // 	'google-site-verification',
  //     'og:title',
  //     'og:description',
  //     'og:url',
  //     'og:image',
  //     'og:type',
  //     'og:site_name',
  //     'og:image:alt',
  //     'twitter:title',
  //     'twitter:description',
  //     'twitter:image',
  //   ];

  //   React.useEffect(() => {
  //     if (Object.entries(dataSetting?.listSetting)) {
  //       const metaTagElements = metaTags?.map(el => {
  //         const metaTag = document.createElement('meta');
  //         console.log('metaTag', metaTag);
  //         metaTag.setAttribute('name', dataSetting?.listSetting?.title);
  //         metaTag.setAttribute('content', dataSetting?.listSetting?.description);
  //         return metaTag;
  //       });
  //       document.head.append(...metaTagElements);
  //     }
  //   }, [dataSetting]);

  return (
    <>
      {(loading || loadingDefault) && (
        <div className={`${sx ? sx : 'modal-loading'}`}>
          <img src={require('@assets/logo.png')} alt="logo" />
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
