import { IDataRes, IInvite } from '@interfaces/Invite';
import httpRequest from './Api';

const Invite = {
  getAllInvite(): Promise<IDataRes<IInvite>> {
    const url = '/list-invites';
    return httpRequest.get(url);
  },
};

export default Invite;
