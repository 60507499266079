import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import './style.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { IUserLogin } from '@interfaces/UserInterface';
import Loading from '@components/common/loading';
import { authLogin, getCurrentUser } from '@store/authSlice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { handleOpen } from '@store/commonSlice';
import { Link, useNavigate } from 'react-router-dom';
type IModalBasic = {
  children: React.ReactNode;
  url?: string;
};
export default function ModalView({ children, url }: IModalBasic) {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { data } = useAppSelector((state) => state.homeSlice);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleShow = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="modale-view">
      <div className="open-btn" onClick={handleShow}>
        {children}
      </div>
      <Modal className="modal-container-view" closable={true} open={isModalOpen} onCancel={handleCancel} footer={null}>
        <img src={url} alt="" />
      </Modal>
    </div>
  );
}
