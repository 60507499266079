import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getPage } from '@store/detailSlice';
import { useLocation } from 'react-router-dom';
import SEO from '@components/common/seo';
export interface IDetailComponentProps {}

export default function DetailComponent(props: IDetailComponentProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.detailSlice);
  let location = useLocation();
  React.useEffect(() => {
    if (!location) return;
    dispatch(getPage({ slug: location.pathname.split('/')[1] }));
  }, [location]);
  return (
    <>
      <SEO data={data?.pageItem} />
      <div className="layout-full">
        <div className="layout-max-help">
          <div className="heading">
            <span className="text">{data.pageItem?.title || ''}</span>
          </div>
          <div className="conent-page">
            {' '}
            <div dangerouslySetInnerHTML={{ __html: data.pageItem?.content || '' }} />
          </div>
        </div>
      </div>
    </>
  );
}
