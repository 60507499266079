import { useAppDispatch, useAppSelector } from '@hook/index';
import React, { useEffect, useState } from 'react';
import FormSearch from './form/search';
import { IUser } from '@interfaces/User';
import './style.scss';
import { USER } from '@constants/text';
import { ParamReq } from '@interfaces/CommonInterface';
import showMessage from '@utils/helper';
import { getTransfer } from '@store/transferSlice';
import History from './table/History';
import { getPoint } from '@store/pointSlice';

export default function HistoryPointComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.pointSlice);
  const [param, setParam] = useState<ParamReq>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getPoint({
        paginate: param?.paginate || 15,
        page: param?.page || 1,
      }),
    );
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };

  return (
    <>
      <div className="history-deposit">
        <div className="rightModule">
          <div className="infoModule">
            <div className="inside-title icon icon-icon32px_History">
              <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <i style={{ fontSize: '30px' }} className="fa-solid fa-clock-rotate-left"></i>Lịch sử chuyển điểm
              </span>
            </div>
            <div className="quickSelect">
              <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} />
              <History
                data={data.listPoint}
                current_page={data?.current_page || 1}
                total={data?.total || 1}
                loading={loading}
                handleChangeParam={handleChangeParam}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
