import { IDataRes, IDataPointPrice } from '@interfaces/PointPrice';
import httpRequest from './Api';

const PointPrice = {
	getPointPrice(): Promise<IDataRes<IDataPointPrice>> {
    const url = '/list-point-prices';
    return httpRequest.get(url);
  },
};

export default PointPrice;
