import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { convert } from '@utils/convert';
import { ParamReq } from '@interfaces/CommonInterface';
import { useAppSelector } from '@hook/index';
import { isCheckRole } from '@utils/helper';
import { ITransfer } from '@interfaces/Transfer';
import { IPoint } from '@interfaces/Point';
import { IGameUser } from '@interfaces/GameUser';
import TableMobile from '@components/level/table-mobile';
import GameUserList from '@components/table-mobile/GameUser';
export interface IListMember {
  data: IGameUser[];
  current_page: number;
  total: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
}

export default function History(props: IListMember) {
  const { data, current_page, total, loading, handleChangeParam } = props;
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  /** data for column */
  const columns: ColumnsType<IGameUser> = [
    {
      title: 'Hình ảnh',
      dataIndex: 'game',
      key: 'game',
      width: 100,
      align: 'center',
      render: (_, record) => <img style={{ width: '50px' }} src={record.game?.web_pic} />,
    },
    {
      title: 'Tài khoản cá cược',
      dataIndex: 'game',
      key: 'game',
      width: 100,
      align: 'center',
      render: (_, record) => <Space size="middle">{record.game?.title}</Space>,
    },
    {
      title: 'Tên truy cập',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 200,
    },
    {
      title: 'Mật khẩu',
      dataIndex: 'password',
      key: 'password',
      width: 100,
      align: 'center',
    },
    {
      title: 'Điểm',
      dataIndex: 'point',
      key: 'point',
      width: 100,
      align: 'center',
    },
  ];

  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col desktop"
            scroll={{ x: 1024 }}
            loading={loading}
            pagination={false}
            columns={columns || 1}
            dataSource={data}
            rowKey={(record) => record.id}
          />
          <div className="mobile">
            <GameUserList data={data} />
          </div>
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination onChange={ChangePage} current={current_page} total={total} showSizeChanger={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
