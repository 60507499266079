import * as React from 'react';
import Marquee from 'react-fast-marquee';
import './style.scss';
export interface IMarqueeProps {
  content: string;
}

export default function Marquees(props: IMarqueeProps) {
  const { content } = props;
  return (
    <div className="layout-full">
      <div className="layout-max">
        <div id="Marquee">
          <div className="marquee-box">
            <div className="img-sound">
              <img src={require('@assets/body/speaker.png')} alt="img-speaker" />
            </div>
            <Marquee speed={30}>
              <div dangerouslySetInnerHTML={{ __html: content }} />{' '}
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
}
