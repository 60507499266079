import * as React from 'react';

export interface IInfoVipProps {}

export default function InfoVip(props: IInfoVipProps) {
  return (
    <div className="layout-full">
      <div className="layout-max">
        <div className="vipInfo">
          <div className="vipTitle">Đặc quyền VIP</div>viva88viet vừa ra mắt chương trình thưởng Đặc quyền VIP với những
          ưu đãi chưa từng có: dịch vụ đặc biệt, quà tặng hoành tráng, và trải nghiệm khách hàng đỉnh cao. Hạng càng cao
          đặc quyền càng hấp dẫn. Đăng ký ngay hôm nay, quà tặng hấp dẫn đang chờ bạn!
          <div className="vipTitle">Giới thiệu</div>Hành trình vươn lên đỉnh cao VIP gồm các hạng ĐỒNG, BẠC, VÀNG, BẠCH
          KIM, KIM CƯƠNG, và viva88viet CLUB. Thứ hạng càng cao, sản phẩm và dịch vụ càng đặc biệt.
          <div className="vipTitle">Trải nghiệm quyền lợi dành riêng cho từng hạng thành viên.</div>
        </div>
      </div>
    </div>
  );
}
