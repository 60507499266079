import { IDataRes } from '@interfaces/Level';
import httpRequest from './Api';

const Level = {
  getAllLevel(): Promise<IDataRes> {
    const url = '/vip';
    return httpRequest.get(url);
  },
};

export default Level;
