import { IDataResListDataTransfer, IParamListDataTransfer, ITransfer, ITransferDeposit } from '@interfaces/Transfer';
import httpRequest from './Api';

const Transfer = {
  getAllTransfer({ ...rest }: Partial<IParamListDataTransfer>): Promise<IDataResListDataTransfer<ITransfer>> {
    const url = '/list-transfer-moneys';
    return httpRequest.get(url, { params: { ...rest } });
  },

  deposit({ ...rest }: ITransferDeposit): Promise<{ messages: string }> {
    const url = '/create-transfer-money';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
};

export default Transfer;
